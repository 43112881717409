// @refresh reset

import { Layout } from 'antd';

import { LandingPage } from '@/containers';

const IndexPage = () => {
  return (
    <Layout>
      <LandingPage />
    </Layout>
  );
};

export default IndexPage;
